<template>
    <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList' : 'Shop_ApprovalInfoList_PC'">
        <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList_bottom' : 'Shop_ApprovalInfoList_bottom_PC'">
            <div style="width: 50%;text-align: center;border-right: 1px solid #ccc;box-sizing: border-box;">
                <i class="el-icon-tickets" style="font-size: 30px;color: #D1100B;"></i>
            </div>
            <div style="width: 50%;text-align: center;" @click="$router.push('./ExternalUserInfo')">
                <i class="el-icon-user-solid" style="font-size: 30px;"></i>
            </div>
        </div>
        <div style="padding: 10px;">
            <el-tabs v-model="activeName" type="border-card" stretch>
                <el-tab-pane label="待审列表" name="待审列表" v-loading="loading">
                    <div style="position: sticky;top: 0;left: 0;height: 50px;line-height: 50px;background-color: #fff;">
                        <el-input placeholder="请输入内容" v-model="SerachValue">
                            <el-select v-model="SerachSelect" slot="prepend" placeholder="请选择" style="width: 110px;">
                                <el-option label="项目名称" value="ProjectName"></el-option>
                                <el-option label="任务名称" value="TaskName"></el-option>
                                <el-option label="商铺名称" value="ShopName"></el-option>
                                <el-option label="报审标题" value="ApprovalName"></el-option>
                            </el-select>
                            <template slot="append">
                                <i class="el-icon-search" @click="search"></i>
                            </template>
                        </el-input>
                    </div>
                    <div style="border-bottom: 1px solid #ccc;" v-for="(item, index) in dataList" :key="index"
                        @click="clickItem(item)">
                        <div style="display: flex;align-items: center;">
                            <div style="width: calc(100% - 20px);">
                                <div style="font-size: 16px;font-weight: bold;text-align: center;line-height: 30px;"
                                    class="ellipsis" :title="item.ApprovalName">{{
                                        item.ApprovalName
                                    }}</div>
                                <div style="line-height: 30px;font-size: 14px;">
                                    <div class="ellipsis">
                                        项目：{{ item.ProjectName }}
                                    </div>
                                    <div class="ellipsis">
                                        任务：{{ item.TaskName }}
                                    </div>
                                    <div class="ellipsis">
                                        商铺：{{ item.ShopName }}
                                    </div>
                                    <div class="ellipsis">
                                        商铺编号：{{ item.ShopCode }}
                                    </div>
                                </div>
                                <div
                                    style="display: flex;justify-content: space-between;line-height: 30px;align-items: center;">
                                    <el-tag effect="plain" size="mini">{{
                                        item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : ''
                                        }}</el-tag>
                                    <div style="font-size: 12px;">{{ item.CreateTime ? item.CreateTime.substring(0, 19)
                                        : ""
                                        }}</div>
                                </div>
                            </div>
                            <div style="width: 20px;">
                                <i class="el-icon-arrow-right" style="font-size: 30px;width: 20px;"></i>
                            </div>
                        </div>



                    </div>
                </el-tab-pane>
                <el-tab-pane label="已审列表" name="已审列表" v-loading="loading">
                    <div style="position: sticky;top: 0;left: 0;height: 50px;line-height: 50px;background-color: #fff;">
                        <el-input placeholder="请输入内容" v-model="SerachValue2">
                            <el-select v-model="SerachSelect2" slot="prepend" placeholder="请选择" style="width: 110px;">
                                <el-option label="项目名称" value="ProjectName"></el-option>
                                <el-option label="任务名称" value="TaskName"></el-option>
                                <el-option label="商铺名称" value="ShopName"></el-option>
                                <el-option label="报审标题" value="ApprovalName"></el-option>
                            </el-select>
                            <template slot="append">
                                <i class="el-icon-search" @click="searc2"></i>
                            </template>
                        </el-input>
                    </div>
                    <div style="border-bottom: 1px solid #ccc;" v-for="(item, index) in ReviewedDataList" :key="index"
                        @click="clickItem(item, 1)">
                        <div style="display: flex;align-items: center;">
                            <div style="width: calc(100% - 20px);">
                                <div style="font-size: 16px;font-weight: bold;text-align: center;line-height: 30px;"
                                    class="ellipsis" :title="item.ApprovalName">{{
                                        item.ApprovalName
                                    }}</div>
                                <div style="line-height: 30px;font-size: 14px;">
                                    <div class="ellipsis">
                                        项目：{{ item.ProjectName }}
                                    </div>
                                    <div class="ellipsis">
                                        任务：{{ item.TaskName }}
                                    </div>
                                    <div class="ellipsis">
                                        商铺：{{ item.ShopName }}
                                    </div>
                                    <div class="ellipsis">
                                        商铺编号：{{ item.ShopCode }}
                                    </div>
                                </div>
                                <div
                                    style="display: flex;justify-content: space-between;line-height: 30px;align-items: center;">
                                    <el-tag effect="plain" size="mini">{{
                                        item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : ''
                                        }}</el-tag>
                                    <div style="font-size: 12px;">{{ item.CreateTime.substring(0, 19)
                                        }}</div>
                                </div>
                            </div>
                            <div style="width: 20px;">
                                <i class="el-icon-arrow-right" style="font-size: 30px;width: 20px;"></i>
                            </div>
                        </div>



                    </div>
                    <el-empty description="暂无数据" v-if="ReviewedDataList.length == 0"></el-empty>
                </el-tab-pane>

            </el-tabs>


        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataList: [
                {
                    type: '设备材料',
                    code: '001',
                    status: 0,
                    num: 1,
                    createdTime: '2021-01-01 12:00',
                    id: 1,
                    MainTitle: "设备材料审核",
                    SubTitle: "副标题",
                    ReportUnit: "提报单位A",
                    SignatoryUnit: ['1', '2', '3'],
                    addAuditUpFileData: [
                        {
                            "name": "6666.jpg",
                            "size": 39248,
                            "url": "https://api.zytsaas.com/Shop_ExtensionInfo/20240510/486f9e834ae94bcb9d73c7f7e7a7a996/1788851544758685696.jpg",
                            "uid": 1715330417737,
                            "status": "success"
                        },
                        {
                            "name": "123.jpg",
                            "size": 274230,
                            "url": "https://api.zytsaas.com/Shop_ExtensionInfo/20240510/28bcee835ed64dc4b4f2f5efac0af4de/1788851564425777152.jpg",
                            "uid": 1715330422415,
                            "status": "success"
                        }
                    ],
                },

                {
                    type: '设备材料',
                    code: '002',
                    status: 0,
                    num: 2,
                    createdTime: '2021-01-02 15:00',
                    id: 2,
                    MainTitle: "设备图纸材料审核",
                    SubTitle: "材料审核",
                    ReportUnit: "提报单位B",
                    SignatoryUnit: ['1', '2', '3'],
                    addAuditUpFileData: [
                        {
                            "name": "6666.jpg",
                            "size": 39248,
                            "url": "https://api.zytsaas.com/Shop_ExtensionInfo/20240510/486f9e834ae94bcb9d73c7f7e7a7a996/1788851544758685696.jpg",
                            "uid": 1715330417737,
                            "status": "success"
                        },
                        {
                            "name": "123.jpg",
                            "size": 274230,
                            "url": "https://api.zytsaas.com/Shop_ExtensionInfo/20240510/28bcee835ed64dc4b4f2f5efac0af4de/1788851564425777152.jpg",
                            "uid": 1715330422415,
                            "status": "success"
                        }
                    ],
                },
            ],
            SerachSelect: 'ApprovalName',
            SerachValue: '',
            SerachSelect2: 'ApprovalName',
            SerachValue2: '',
            loading: false,
            ReviewedDataList: [],
            activeName: '待审列表',
            userInfo: {},
            isPCH5Text: ""
        };
    },
    props: {},
    components: {},
    methods: {
        search() {
            this.getDataListByPhoneForUnreviewed()
        },
        searc2() {
            this.getDataListByPhoneForReviewed()
        },
        getDataListByPhoneForUnreviewed() {
            this.loading = true
            let obj = {}
            if (this.SerachSelect) {
                obj = {
                    "Condition": this.SerachSelect,
                    "Keyword": this.SerachValue
                }
            }
            this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetDataListByPhoneForUnreviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    { ...obj },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                this.loading = false
                if (res.Success) {
                    this.dataList = res.Data || [];
                } else {
                    this.$phoneMessage(res.Msg);
                }
            })
        },
        getDataListByPhoneForReviewed() {

            this.loading = true
            let obj = {}
            if (this.SerachSelect) {
                obj = {
                    "Condition": this.SerachSelect2,
                    "Keyword": this.SerachValue2
                }
            }
            this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetDataListByPhoneForReviewed', {
                // "PageIndex": 0,
                // "PageRows": 0,
                SortField: "CreateTime",
                SortType: "desc",
                "Search": [
                    { ...obj },
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    }
                ]
            }).then(res => {
                this.loading = false
                if (res.Success) {
                    this.ReviewedDataList = res.Data || [];
                } else {
                    this.$phoneMessage(res.Msg);
                }
            })
        },
        clickItem(item, num) {
            this.$router.push({
                path: '/Shop_ApprovalInfoDetail',
                query: {
                    Id: item.Id,
                    num: num || 0,
                    ApprovalType: item.ApprovalType,
                    ShopId: item.ShopId,
                }
            })
        },
        getSystemInfo() {
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                return "微信"
            } else {
                // 判断h5还是pc true就是h5
                let client =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );
                if (client) {
                    return "H5"
                } else {
                    return "PC"
                }
            }
        },
    },
    mounted() { },
    created() {
    },
    activated() {
        this.userInfo = window.localStorage.getItem('EuInfo') ? JSON.parse(window.localStorage.getItem('EuInfo')) : {};
        this.getDataListByPhoneForReviewed()
        this.getDataListByPhoneForUnreviewed()
        this.isPCH5Text = this.getSystemInfo()
        window.addEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })
    },
    computed: {},
    watch: {
        activeName(val) {
            console.log(val)
            if (val === '待审列表') {
                this.getDataListByPhoneForUnreviewed()
            } else if (val === '已审列表') {
                this.getDataListByPhoneForReviewed()
            }
        }
    },
};
</script>
<style lang='scss' scoped>
.Shop_ApprovalInfoList {
    width: 100vw;
    height: 100vh;
    // padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}

::v-deep .el-tabs__content {
    height: calc(100vh - 125px);
    overflow-y: auto;

    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
}

.ellipsis {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 1; //内容限制的行数
}

.Shop_ApprovalInfoList_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}

.Shop_ApprovalInfoList_PC {
    width: 100vw;
    padding: 0 30vw;
    height: 100vh;
    // padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}

.Shop_ApprovalInfoList_bottom_PC {
    position: fixed;
    bottom: 0;
    left: calc(30vw + 10px);
    height: 60px;
    width: calc(40vw - 20px);
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}
</style>